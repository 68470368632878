<template>
  <v-container fluid>
    <v-toolbar flat class="pb-10">
      <v-text-field
          class="d-print-none"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
      />
      <v-spacer/>
      <v-btn-toggle
          v-model="search"
      >
        <v-btn
            v-for="team of teamFilter"
            v-bind:key="team"
            :value="`team:${team}`"
        >{{ team }}
        </v-btn>

      </v-btn-toggle>

      <v-spacer/>
      <v-text-field :value="datesFormatted"
                    persistent-hint
                    hint="Task Dates"
                    label="Select dates"
                    single-line
                    readonly
                    @click="showDatePicker=true"
                    append-icon="mdi-calendar"
      />

      <v-dialog
          v-model="showDatePicker"
          ref="dialog"
          width="290px"
          persistent
      >
        <v-date-picker
            v-model="dates"
            no-title
            range
        ></v-date-picker>
        <v-btn @click="showDatePicker=false; dates.sort()">Close</v-btn>
      </v-dialog>
    </v-toolbar>
    <TaskTable
      :dates="dates"
    />

  </v-container>
</template>

<script>
import TaskTable from '@/components/tasks/TaskTable';
import moment from 'moment';

export default {
  name: "TaskReport",
  components: {TaskTable},
  data() {
    return {
      showDatePicker: null,
      dates: [],
      search: null,
      teamFilter: [],
    }
  },
  computed: {
    datesFormatted() {
      const format = 'dddd MMMM D';
      const from = this.dates[0];
      const to = this.dates[1];
      if (!from) {
        return '';
      } else {
        if (from === to) {
          return `${moment(from).format(format)}`;
        } else {
          return `${moment(from).format(format)} - ${moment(to).format(format)}`
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
