import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VToolbar,{staticClass:"pb-10",attrs:{"flat":""}},[_c(VTextField,{staticClass:"d-print-none",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VSpacer),_c(VBtnToggle,{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},_vm._l((_vm.teamFilter),function(team){return _c(VBtn,{key:team,attrs:{"value":("team:" + team)}},[_vm._v(_vm._s(team)+" ")])}),1),_c(VSpacer),_c(VTextField,{attrs:{"value":_vm.datesFormatted,"persistent-hint":"","hint":"Task Dates","label":"Select dates","single-line":"","readonly":"","append-icon":"mdi-calendar"},on:{"click":function($event){_vm.showDatePicker=true}}}),_c(VDialog,{ref:"dialog",attrs:{"width":"290px","persistent":""},model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},[_c(VDatePicker,{attrs:{"no-title":"","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}}),_c(VBtn,{on:{"click":function($event){_vm.showDatePicker=false; _vm.dates.sort()}}},[_vm._v("Close")])],1)],1),_c('TaskTable',{attrs:{"dates":_vm.dates}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }