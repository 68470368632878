import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.tasks.length)+" Tasks ")]),_c(VCardActions,[_c(VCheckbox,{attrs:{"label":"Show All"},model:{value:(_vm.showAll),callback:function ($$v) {_vm.showAll=$$v},expression:"showAll"}}),_c(VSpacer),_c(VBtn,{attrs:{"outlined":""},on:{"click":function($event){_vm.csvExport(_vm.dataAsTable,("tasks-" + (_vm.dates.join('-'))))}}},[_vm._v("Download CSV")])],1),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.dataHeaders,"items":_vm.dataAsTable,"disable-pagination":"","hide-default-footer":"","dense":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }