<template>
  <!--  <v-data-table-->
  <!--    :items="tasks"-->
  <!--  />-->
  <v-card flat>
    <v-card-title>
      {{ tasks.length }} Tasks
    </v-card-title>
    <v-card-actions>
      <v-checkbox
          label="Show All"
          v-model="showAll"
      />
      <v-spacer/>
      <v-btn outlined @click="csvExport(dataAsTable,`tasks-${dates.join('-')}`)">Download CSV</v-btn>
    </v-card-actions>
    <v-card-text>
      <v-data-table
          :headers="dataHeaders"
          :items="dataAsTable"
          disable-pagination
          hide-default-footer
          dense
      />
    </v-card-text>
  </v-card>
</template>

<script>
import api from '@/api';
import {dateFormatted, getDateString} from '@/store/utils';

export default {
  name: "TaskTable",

  mounted() {
    return this.updateDateRange();
  },
  watch: {
    dates: 'updateDateRange'
  },
  props: {
    dates: {type: Array, default: () => [], required: true}
  },
  methods: {
    formatDuration(ms) {
      if (ms && !isNaN(ms)) {
        // return moment.utc(ms || 0).format('HH:mm:ss');
        return ((ms / 1000) / 60).toFixed(2);
      } else {
        return ''
      }
    },
    updateDateRange() {
      const [from, to] = this.dates;
      console.log('range', from, to);
      if (from && to) {
        return api
            .get(`/v2/task`, {
              params: {
                from,
                to
              }
            }).then(result => {
              const {data: tasks} = result;
              console.log('tasks', tasks);
              this.tasks = tasks;
            });
      }
    },
    csvExport(arrData, filename) {
      let csvContent = '';
      const delim = ',';
      csvContent += [
        Object.keys(arrData[0]).map(item => `"${item}"`).join(delim),
        ...arrData.map(item => Object.values(item).map(item => `"${item}"`).join(delim))
      ]
          .join('\n')
          .replace(/(^\[)|(\]$)/gm, '');

      const link = document.createElement('a');
      const csvBlob = new Blob([csvContent], {type: 'text/csv'});
      link.href = URL.createObjectURL(csvBlob);
      link.setAttribute('download', filename);
      link.click();
    },
    getDuration(start, stop) {
      start = Date.parse(start);
      stop = Date.parse(stop);
      return stop - start;

    },
  },
  computed: {
    dataAsTable() {
      const rows = [];
      for (const task of this.tasks) {
        const {id, title = '', elapsed, description = '', from, to, complete} = task;
        if (!task.execution || task.execution.length === 0) {
          if (this.showAll) {
            rows.push({
              taskId: id,
              title,
              totalDurationInMinutes: this.formatDuration(elapsed),
              people: '',
              durationInMinutes: '',
              from: getDateString(from),
              to: getDateString(to),
              start: '',
              stop: '',
              note: '',
              description,
              status: 'not started'
            })
          }
        } else {
          for (const execution of task.execution) {
            const {start, stop, team_members, note = ''} = execution;
            let status = '';
            if (complete) {
              status = 'completed';
            } else {
              if (stop) {
                status = 'paused'
              } else {
                status = 'running';
              }
            }
            rows.push({
              taskId: id,
              title,
              totalDurationInMinutes: this.formatDuration(elapsed),
              people: team_members.map(t => t.name).join(', '),
              durationInMinutes: this.formatDuration(this.getDuration(start, stop)),
              from: getDateString(from),
              to: getDateString(to),
              start: dateFormatted(start, {formatString: this.timeFormat}),
              stop: dateFormatted(stop, {formatString: this.timeFormat}),
              note,
              description,
              status
            });
          }
        }
      }
      console.log('rows', rows);
      return rows;
    },
    dataHeaders() {
      return Object.keys(this.dataAsTable[0] || {})
          .map(k => ({text: k, value: k}));
    },
  },
  data() {
    return {
      tasks: [],
      timeFormat: 'HH:mm:ss ddd',
      showAll: null
    }
  }
}
</script>

<style scoped>

</style>
